/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page"
            >Order History</a
          >
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Order history
            </li>
          </ol>
        </div>
      </nav>
      <div class="order-history">
        <div class="container">
          <div class="order-history__content">
            <div class="order-history__content--item" v-for="(order, index) in orderList" :key="index">
              <div class="order-head d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <h3 class="mb-0">Order:</h3>
                  <span class="code-order">{{order.paymentId.replace('PAYID-', '')}}</span>
                </div>
                <div class="d-flex align-items-center order-status shipping">
                  <div class="dot-or"></div>
                  <span class="name">{{ lowerCaseChar(order.orderStatus) }}</span>
                </div>
              </div>
              <div class="order-list">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Items</th>
                      <th class="text-center">Price</th>
                      <th class="text-center">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cart, index) in orderListByOrder(order.cartId)" :key="index">
                      <td>
                        <div class="order-list__item">
                          <img :src="cart.thumbnail" alt="" />
                          <div class="order-list__item--content">
                            <h3 class="title">{{ cart.productName }}</h3>
                            <p>{{ cart.productDescription }}</p>
                            <p>Color: {{ cart.cartColor }}</p>
                            <p>Size: {{ cart.cartSize }}</p>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">${{ cart.cartAmount }}</td>
                      <td class="text-center">{{ cart.cartQuantity }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="order-list__total">
                  <div class="order-list__total--date">{{ moment(new Date(order.createdAt * 1000)) }}</div>
                  <div class="d-flex align-items-center">
                    <span>Order total:</span>
                    <span class="total-value">${{ order.orderAmount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>
<script>
/* eslint-disable */
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      orderList: [],
      cartItems: []
    };
  },
  async mounted() {
    const data = await this.getListOrder();
    this.orderList = data['orderList'];
    this.cartItems = data['cartItems'];
  },
  computed: {
  },
  methods: {
    ...mapActions({
      getListOrder: 'order/getListOrder',
    }),
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD hh:mm:ss');
    },
    lowerCaseChar(word) {
      return word.charAt(0) + word.substring(1).toLowerCase();
    },
    orderListByOrder(cartId) {
      return this.cartItems.filter((item) => item.cartId === cartId)
    }
  },
};
</script>
